import React from 'react';
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import Blog1 from 'components/Software/Blog1';
import ChatBanner from 'components/Software/ChatBanner';
import Footer from 'components/Saas/Footer';
import { Link } from 'gatsby';



const CorporateAccount = () => {
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden" data-scroll-index="0" style={{background: '#9c27b0'}}>
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-5">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Your Business, Our Priority. Open a Corporate  <span> Account Today</span></h1>
                <p className="p">Streamline your business operations with our Corporate Accounts designed to provide smooth, hassle-free banking services for companies of all sizes..</p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">   
        
        <img src="/assets/img/about/style_3_1.png" style={{ maxWidth: '30%'}} alt="" />
        <img src="/assets/img/about/CORPORATE.png" alt="" className='img-body' />     
      </div>
    </header>
      <main>
      <section className="about section-padding style-3" data-scroll-index="3">
      
        <div className="container">
          <div className="row gx-0 justify-content-end">
            <div className="col-lg-6">
            <div className="img ">
          <img src="/assets/img/about/CORPORATE.png" alt="" className='img-body' />          
        </div>
            </div>
            <div className="col-lg-6">
              <div className="content">
                <div className="section-head long-shape mb-40 style-3">
                  <h3>Corporate <span>Account</span></h3>
                </div>
                <p className="p">
                Our corporate banking solutions come with easy access to transactions, payroll management, and business loans. Take advantage of tailored financial solutions to help your business grow
                </p>
                <br />
                <div className="text mb-20">
                  <h5>Requirements: </h5>
                  <ul>
                    <li>BVN</li>
                    <li>CAC Documents</li>
                    <li>KYC verification</li>
                    <li>Residence Details</li>                    
                    <li>Utility Bill</li>                    
                  </ul>
                </div>
                <p>Our corporate banking solutions come with easy access to transactions, payroll management, and business loans. Take advantage of tailored financial solutions to help your business grow</p>
                <Link to="/#" className="btn rounded-pill bg-blue2 sm-butn text-white mt-10">
                  <span>Open a Corporate account</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
    
    </section>
       
        <Blog1 />
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>Mutual Trust MFB</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default CorporateAccount;